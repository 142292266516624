import * as React from "react";
import Layout from "../../components/Layout";
import Elements from "./elements/Elements";
import { deentitize } from "../../helpers/common";
import Subscribe from "../../components/Subscribe";
import Banner from "../../components/Banner";
import Breadcrumbs from "../../components/Breadcrumb/Breadcrumbs";
import { Container } from "react-bootstrap";
import Title from "../../components/Layout/Title";
import Section from "../../components/Layout/Section";
import { Helmet } from 'react-helmet'

const NewsArticlePage = ({ pageContext: { page, nodes } }) => {
  const elementalContent = page.elementalContent ? deentitize(page.elementalContent) : null
  const breadcrumbs_pages = [page.parent, page]
  const hasBanner = page.bannerImage && page.bannerImage.absoluteLink
  return (
    <Layout>
        <Helmet>
             <title>{ page.title } | Grocorp Furniture</title>
        </Helmet>
      {hasBanner && (
        <Banner title={page.title} imageURL={page.bannerImage.absoluteLink} />
      )}
      <Breadcrumbs pages={breadcrumbs_pages} />
      <Section noSpacingTop noSpacingBottom>
        <Container>
          <Title title={page.title} className="text-center">
            <div className="tag-wrap subtitle subtitle-sm">
              {page.categories.nodes.length > 0 &&
                page.categories.nodes.map((category, idx) => {
                  let isLast = idx === page.categories.nodes.length - 1;
                  return (
                    <>
                      <span
                        className="tag-item"
                        key={`category-${category.id}`}
                      >
                      <a className="text-decoration-none" href={`${page.parent.link}?category=${category.urlSegment}`}>
                        {category.name}
                        </a>
                      </span>
                      {isLast ? "" : <span>-</span>}
                    </>
                  );
                })}
              <span>Published: {page.publishedDate}</span>
            </div>
            <p>{page.summary}</p>
          </Title>
        </Container>
      </Section>
      <div className="section blog">
        {elementalContent && (
        <Elements data={elementalContent} />
        )}
        <Subscribe />
      </div>
    </Layout>
  );
};

export default NewsArticlePage;
