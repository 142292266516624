import * as React from "react"
import { Breadcrumb, Container } from "react-bootstrap"

const Breadcrumbs = ({pages}) => {
    const max_deep = pages.length - 1
    return (
        <Container>
            <Breadcrumb className="main-breadcrumb">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                {pages.length > 0 && (
                    <>
                        {pages.map((page, idx) => {
                           if(idx === max_deep){
                                return(
                                     <Breadcrumb.Item key={`breadcrumbs-${page.id}`} active>{page.title}</Breadcrumb.Item>
                                ) 
                            }else{
                                return(
                                     <Breadcrumb.Item key={`breadcrumbs-${page.id}`} href={page.link}>{page.title}</Breadcrumb.Item>
                                ) 
                            }
                        })}
                    </>
                )}
                
            </Breadcrumb>
        </Container>
    )
}

export default Breadcrumbs
